/*
* Author        : @Syanapsys
* Created date  : 02/03/2032
* Discription   : Disable Enable buttons based on user roll

Component Logic And Usage
********************************************************************************************************************************
1. Enable Import button  only on SUPER_ADMIN
********************************************************************************************************************************
*/

/*
@Name       : disableEnableButtons
@Parameters : button_type (Import) , user_roll (SUPER_ADMIN/ACCOUNT_ADMIN/SCHOOL_ADMIN/TEACHER)
@Return     : Returning true to disable element and returning false to enable
*/
export const disableEnableButtons = (button_type = null, user_roll = null) => {
    if (button_type != null && user_roll != null) {
        /*On Dropdown Type Account*/

        if (button_type === "Import") {
            if (user_roll === "SUPER_ADMIN") {
                return false;
            } else if (user_roll === "ACCOUNT_ADMIN") {
                return false;
            } else if (user_roll === "SCHOOL_ADMIN") {
                return true;
            } else if (user_roll === "TEACHER") {
                return true;
            }
        }

        if(button_type === "Permission1") {
            if (user_roll === "SUPER_ADMIN") {
                return false;
            } else if (user_roll === "ACCOUNT_ADMIN") {
                return true;
            } else if (user_roll === "SCHOOL_ADMIN") {
                return true;
            } else if (user_roll === "TEACHER") {
                return true;
            }
        }

        

    } else {
        /*Errors On Console*/
        if (button_type === null) {
            console.error("disableEnableButtons: Please set Button Type");
        } else if (user_roll === null) {
            console.error("disableEnableButtons: Please set User Roll");
        }
    }
}
