import { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Link } from "react-router-dom";
// reactstrap components
import { Card, CardText, CardImg, Row, Col, CardBody } from "reactstrap";
import { Button } from "react-bootstrap";
import { Tabs, Input, Table, Space, Drawer } from "antd";
import "antd/dist/antd.css";
import Create from "./Create";
import View from "./view";
import jsonToken from 'jsonwebtoken';
import { disableEnableButtons } from "../../../../components/DisableEnableBasedOnAccess/DisableEnableButtons";
import ImportClass from './Import';

function handleDownload() {
  const csvData = "class_code,class_name,class_description,account_name,school_name,teacher_username,class_level_name,curriculum_name,term_name,start_date,end_date,expired_class_codes"
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "CSV_FileFormate.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function Classes(props) {
  const [flag, setFlag] = useState(false);
  const [key, setKey] = useState(1);
  const [open, setOpen] = useState(false);
  let accessToken = jsonToken.decode(localStorage.getItem("access_token"));

  const onChange = (key) => {
    console.log(key);
  };
  useEffect(() => {
    setFlag(false)
  }, [props])

  useEffect(() => {
    if (props.activeTab == props.tabId) {
      var tempKey = key + 1;
      setKey(tempKey)
    }
  }, [props.activeTab])

  const showDrawer = () => {
    setOpen(true);
    // setSize('default')
  }
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="container-fluid admin-tabs">
        <Row>
          <Col md="12">
            <Card className="border-0">
              <CardBody className="e">
                <Row>
                  <Col md="6">
                    <h5>Class</h5>
                  </Col>
                  <Col md="6">
                    <div className="pull-right">
                      <Button
                        hidden={disableEnableButtons("Permission1", accessToken.roles[0])}
                        type="danger"
                        className="mr10 mb20"
                        onClick={() => setFlag(!flag)}
                        size="sm"
                      >
                        {flag ? "View" : "New"}
                      </Button>
                      <Button
                        hidden={disableEnableButtons("Permission1", accessToken.roles[0])}
                        type="primary"
                        className="mb20"
                        size="sm"
                        onClick={showDrawer}
                      >
                        Import
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {flag ? <Create a={flag} tabId={props.tabId} setFlag={setFlag} user_roll={accessToken.roles[0]} /> : <View user_roll={accessToken.roles[0]} key={key} h={flag} activeTab={props.activeTab} tabId={props.tabId} open={open} />}

                  </Col>
                </Row>
              </CardBody>
              <Drawer
                title="Import Class List"
                placement="right"
                className="import-drawer"
                onClose={onClose}
                destroyOnClose={true}
                open={open}
                extra={
                  <Space>
                    <Button type="primary" onClick={handleDownload}>
                      Sample CSV
                    </Button>
                  </Space>
                }
              >
                <ImportClass data={3} />
              </Drawer>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Classes;
